import React, { useState } from 'react';

import ComingSoon from './components/ComingSoon';
import Header from './components/Header';
import { InstagramEmbed } from 'react-social-media-embed';
import Slogan from './components/Slogan';

function App() {
  const [loadInstagram, setLoadInstagram] = useState(false);

  const handleLoadInstagram = () => {
    setLoadInstagram(true);
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen">
      <div className='w-full flex-shrink-0 flex justify-center'>
        <Header />
      </div>
      <div className=''>
        <Slogan />
        <ComingSoon />

        {/* Bedingte Anzeige des Instagram-Feeds */}
        <div className='pt-4 flex justify-center'>
          {loadInstagram ? (
            <div 
              className="overflow-auto" 
              style={{ maxHeight: '500px', width: '100%', maxWidth: '400px' }}  // Maxhöhe und Breite definieren
            >
              <InstagramEmbed url="https://www.instagram.com/tealswing/" />
            </div>
          ) : (
            <button 
              onClick={handleLoadInstagram} 
              className="mt-4 px-4 py-2 bg-teal-600 text-white rounded hover:bg-teal-700 focus:outline-none"
            >
              Load Instagram feed
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default App;
