import React from 'react';

function Slogan() {
  return (
    <p className="tealswing-slogan text-xl text-gray-700 mt-4">
      Is like playing golf with teal's wings.
    </p>
  );
}

export default Slogan;
