import React from 'react';

function Header() {
  return (
    <h1 className="tealswing-logo text-6xl md:text-9xl text-teal-600 font-bold">
      Tealswing
    </h1>
  );
}

export default Header;
