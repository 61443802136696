import React from 'react';

function ComingSoon() {
  return (
    <>
    <p className="tealswing-slogan text-sm text-gray-500 mt-8">
      The website is under construction. Stay tuned!
    </p>
    </>
  );
}

export default ComingSoon;
